<template>
  <div
    class="popup_wrap"
    style="width:800px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <form id="frm">
      <div class="popup_cont">
        <h1 class="page_title">{{ $t('msg.VOSD100.040') }}</h1><!-- 스케줄 구독 -->
        <div class="content_box">
          <table class="tbl_row">
            <colgroup>
              <col width="120"><col>
            </colgroup>
            <tbody>
              <template v-if="parentInfo.polPlcNm !== '' && parentInfo.podPlcNm !== ''"><!-- '스케줄'에서 호출된 경우 -->
                <tr>
                  <th>{{ $t('msg.VOSD100.002') }}</th><!-- 출발 -->
                  <td>{{ parentInfo.polPlcNm }}</td>
                </tr>
                <tr>
                  <th>{{ $t('msg.VOSD100.003') }}</th><!-- 도착 -->
                  <td>{{ parentInfo.podPlcNm }}</td>
                </tr>
              </template>
              <template v-else><!-- '나의 정보'에서 호출된 경우 -->
                <tr>
                  <th>{{ $t('msg.VOSD100.002') }}</th><!-- 출발 -->
                  <td>
                    <e-auto-complete-place
                      @change="changePol"
                      :ctr-cd="params.polCtrCd"
                      :plc-cd="params.polPlcCd"
                      :is-char="true"
                      :is-char-alert="true"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('msg.VOSD100.003') }}</th><!-- 도착 -->
                  <td>
                    <e-auto-complete-place
                      @change="changePod"
                      :ctr-cd="params.podCtrCd"
                      :plc-cd="params.podPlcCd"
                      :is-char="true"
                      :is-char-alert="true"
                    />
                  </td>
                </tr>
              </template>
              <tr>
                <th>{{ $t('msg.VOSD100.049') }}</th><!-- 수출/수입 -->
                <td>
                  <input
                    v-model="params.eiCatCd"
                    type="radio"
                    id="im1"
                    name="im"
                    value="O"
                  >
                  <label for="im1" class="mr20"><span></span>{{ $t('msg.VOSD100.050') }}</label><!-- 수출&출항일 -->
                  <input
                    v-model="params.eiCatCd"
                    type="radio"
                    id="im2"
                    name="im"
                    value="I"
                  >
                  <label for="im2"><span></span>{{ $t('msg.VOSD100.051') }}</label><!-- 수입&입항일 -->
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.VOSD100.006') }}</th><!-- 발송 주기 예약 -->
                <td>
                  <select
                    v-model="params.sntCyc"
                    class="wid150"
                  >
                    <option v-for="(week, index) in weekList" :key="week.cd" :value="week.cd"> {{ $t('msg.VOSD100.054') }} {{ lang === 'KOR' ? week.cdNm : ($t(`comp.00${2 + index}`) + ' ' ) }}{{ $t('msg.VOSD100.055') }}</option>
                  </select>
                  <p class="txt_desc none mt5">{{ $t('msg.VOSD100.007') }}</p><!-- Report 발송 시간은 고객님의 현지시간(Local Time) 기준입니다. -->
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.VOSD100.008') }}</th><!-- 조회기간 -->
                <td>
                  <select
                    v-model="params.schTrm"
                    class="wid150"
                  >
                    <option value="1">{{ $t('msg.VOSD100.026') }}</option><!-- 1 Week -->
                    <option value="2">{{ $t('msg.VOSD100.027') }}</option><!-- 2 Weeks -->
                    <option value="3">{{ $t('msg.VOSD100.028') }}</option><!-- 1 Month -->
                  </select>
                  <p class="txt_desc none mt5">{{ $t('msg.VOSD100.009') }}</p><!-- 예약 요일에 선택하신 기간의 스케줄이 발송됩니다. Ex)1week : 11.11(월)~11.17(토) 스케줄 -->
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.VOSD100.010') }}</th><!-- e-mail -->
                <td>
                  <input
                    v-model="params.recptEmlAddr"
                    id="inp_email"
                    type="text"
                    @keyup="checkValidationEmail()"
                    @keypress.enter="(e) => { e.preventDefault() }"
                  >
                  <p class="txt_desc none mt5">{{ $t('msg.VOSD100.011') }}</p><!-- 스케줄을 공유하고자 하시는 e-mail 주소들은 ','으로 구분해 주세요. -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt10 text_center">
          <!-- 저장 -->
          <a
            class="button blue lg"
            href="#"
            @click.prevent="insertEmailSchedule"
          >
            {{ $t('msg.VOSD100.041') }}
          </a>
          <!-- 닫기 -->
          <a
            class="button gray lg"
            href="#"
            @click.prevent="$emit('close')"
          >
            {{ $t('msg.VOSD100.042') }}
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import emailSchedule from '@/api/rest/schedule/emailSchedule'
import commons from '@/api/services/commons'

export default {
  name: 'EmailSchedulePop',
  components: {
    'e-auto-complete-place': () => import('@/components/common/EAutoCompletePlace')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          polCtrCd: '',
          polPlcCd: '',
          polPlcNm: '',

          podCtrCd: '',
          podPlcCd: '',
          podPlcNm: '',

          eiCatCd: ''
        }
      }
    }
  },
  data () {
    return {
      // 스케쥴 구독 저장 API 실행 여부
      isProcess: false,
      commCodes: {},
      // 요일 리스트
      weekList: [],
      params: {
        polCtrCd: '',
        polPlcCd: '',

        podCtrCd: '',
        podPlcCd: '',

        eiCatCd: this.$ekmtcCommon.isNotEmpty(this.parentInfo.eiCatCd) ? this.parentInfo.eiCatCd : 'O',

        sntCyc: '1',
        schTrm: '1',
        recptEmlAddr: ''
      }
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.initParam()
  },
  methods: {
    // init parameter
    async initParam () {
      this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
      this.params = { ...this.params, ...this.parentInfo }

      const arrCdId = ['00004']

      //요일 공통코드 가져오기
      this.commCodes = await commons.getCommonsCodesCommon(arrCdId)

      this.weekList = this.commCodes['00004']?.sort((a, b) => a.cd - b.cd)
    },
    // 이메일 입력여부 체크
    checkValidationEmail (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#inp_email')
      // const val = elem.value
      const val = this.params.recptEmlAddr
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(val)) {
        // 값이 없을 경우
        msg = this.$t('art.CMATK089') + this.$t('tem.CMAT002') // e-mail 주소를 입력하세요.
        isOk = false
      } else {
        // 이메일 유효성 및 중복체크
        const emailStr = val

        if (emailStr.indexOf(';') > 0) {
          // 이메일 여러 개

          // const emailStrArr = emailStr.trim().split(',')
          const emailStrArr = emailStr.trim().replace(/ /gi, '').split(';')

          if (emailStrArr.length > 0) {
            // 중복 검사
            for (let i = 0; i < emailStrArr.length; i++) {
              for (let j = 0; j < emailStrArr.length; j++) {
                if (i !== j) {
                  if (emailStrArr[i] === emailStrArr[j]) {
                    msg = this.$t('msg.VOSD100.046') // 중복으로 입력된 e-mail이 있습니다.
                    isOk = false
                  }
                }
              }
            }

            // 중복 검사 완료된 경우
            if (isOk) {
              emailStrArr.forEach((item, index) => {
                // 이메일 형식 체크
                if (!this.$ekmtcCommon.checkEmail(item)) {
                  msg = (index + 1) + this.$t('msg.VOSD100.047') // 번째 이메일이 형식에 맞지 않습니다.
                  isOk = false
                }

                if (!isOk) return false

                //  Byte 크기 체크
                if (!this.$ekmtcCommon.checkTextByte(item, 4000)) {
                  msg = (index + 1) + this.$t('msg.VOSD100.048') // 번째 이메일이 허용된 Byte 크기를 초과하였습니다.
                  isOk = false
                }

                if (!isOk) return false
              })
            }
          }
        } else {
          // 이메일 1개

          // 이메일 형식 체크
          if (!this.$ekmtcCommon.checkEmail(emailStr)) {
            msg = this.$t('msg.VOSD100.043') // 이메일 형식에 맞춰서 작성해주세요.
            isOk = false
          }
          //  Byte 크기 체크
          if (!this.$ekmtcCommon.checkTextByte(emailStr, 4000)) {
            msg = this.$t('msg.VOSD100.044') // 허용된 Byte 크기를 초과하였습니다.
            isOk = false
          }
        }
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    // 스케쥴 구독 저장
    async insertEmailSchedule () {
      if (this.isProcess) return

      // 이메일 유효 체크
      if (this.checkValidationEmail()) {
        this.isProcess = true

        // this.params = { ...this.params, ...this.parentInfo }

        const TH = this

        await emailSchedule.insertEmailSchedule(TH.params)
          .then(response => {
            if (response.headers.respcode === 'C0000') {
              // 저장되었습니다.
              TH.$ekmtcCommon.alertCallback(TH.$t('tem.CMAT013'), () => {
                TH.$emit('close')
              })
            } else {
              TH.$ekmtcCommon.alertDefault(TH.$t('msg.VOSD100.045')) // 저장에 실패하였습니다.
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.isProcess = false
          })
      }
    },
    // '출발' 자동완성 change event
    changePol (vo) {
      console.log('######## changePol')
      console.log(vo)
      this.params.polCtrCd = vo.ctrCd
      this.params.polPlcCd = vo.plcCd
    },
    // '도착' 자동완성 change event
    changePod (vo) {
      console.log('######## changePod')
      console.log(vo)
      this.params.podCtrCd = vo.ctrCd
      this.params.podPlcCd = vo.plcCd
    }
  }
}
</script>
