var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.parentInfo.yzrYn !== "Y",
            expression: "parentInfo.yzrYn !== 'Y'",
          },
        ],
        staticClass: "content_box beforetab",
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.fromBkg === "N",
                expression: "fromBkg === 'N'",
              },
            ],
          },
          [
            _c(
              "div",
              { staticClass: "popup_dim", attrs: { id: "sch_popup" } },
              [
                _vm.customComponentSch
                  ? _c(_vm.customComponentSch, {
                      tag: "component",
                      attrs: { "parent-info": _vm.parentInfoSch },
                      on: {
                        close: _vm.closePopup,
                        selectFunc: _vm.selectFunc,
                        booking: _vm.bookingBooking2,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c("form", { attrs: { id: "frmLeg" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.parentInfo.yzrYn !== "Y",
                  expression: "parentInfo.yzrYn !== 'Y'",
                },
              ],
              staticClass: "border_bottom_gray_box",
            },
            [
              _c("table", { staticClass: "tbl_search" }, [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.002")))]),
                    _c(
                      "td",
                      [
                        _c("e-auto-complete-place", {
                          attrs: {
                            "auto-focus": true,
                            id: "polPol",
                            "ctr-cd": _vm.pol.ctrCd,
                            "plc-cd": _vm.pol.plcCd,
                            "is-char": true,
                            "is-char-alert": true,
                          },
                          on: { change: _vm.changePol },
                        }),
                      ],
                      1
                    ),
                    _c("th", [
                      _c("span", { staticClass: "ml20" }, [
                        _vm._v(_vm._s(_vm.$t("msg.VOSD100.003"))),
                      ]),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-auto-complete-place", {
                          attrs: {
                            id: "podPod",
                            "ctr-cd": _vm.pod.ctrCd,
                            "plc-cd": _vm.pod.plcCd,
                            "is-char": true,
                            "is-char-alert": true,
                          },
                          on: { change: _vm.changePod },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.032")))]),
                    _vm._v(" "),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c("span", [
                        _c(
                          "div",
                          { staticClass: "input_calendar mid" },
                          [
                            _c("e-month-picker", {
                              model: {
                                value: _vm.yyyymmLeg,
                                callback: function ($$v) {
                                  _vm.yyyymmLeg = $$v
                                },
                                expression: "yyyymmLeg",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.bkgOrNot === false,
                              expression: "bkgOrNot === false",
                            },
                          ],
                          staticClass: "ml15",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.bound,
                                expression: "regParams.bound",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              id: "trad1",
                              name: "trad",
                              value: "O",
                              checked: "checked",
                            },
                            domProps: {
                              checked: _vm._q(_vm.regParams.bound, "O"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.regParams, "bound", "O")
                              },
                            },
                          }),
                          _c(
                            "label",
                            { staticClass: "mr10", attrs: { for: "trad1" } },
                            [
                              _c("span"),
                              _vm._v(_vm._s(_vm.$t("msg.VOSD100_M3.008"))),
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.bound,
                                expression: "regParams.bound",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              id: "trad2",
                              name: "trad",
                              value: "I",
                            },
                            domProps: {
                              checked: _vm._q(_vm.regParams.bound, "I"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.regParams, "bound", "I")
                              },
                            },
                          }),
                          _c(
                            "label",
                            { staticClass: "mr10", attrs: { for: "trad2" } },
                            [
                              _c("span"),
                              _vm._v(_vm._s(_vm.$t("msg.VOSD100_M3.007"))),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("td", { staticClass: "text_right" }, [
                      _c("span", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.checkBkg,
                              expression: "regParams.checkBkg",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "bk1",
                            name: "bk",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.regParams.checkBkg)
                              ? _vm._i(_vm.regParams.checkBkg, null) > -1
                              : _vm._q(_vm.regParams.checkBkg, "Y"),
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.regParams.checkBkg,
                                $$el = $event.target,
                                $$c = $$el.checked ? "Y" : "N"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.regParams,
                                      "checkBkg",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.regParams,
                                      "checkBkg",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.regParams, "checkBkg", $$c)
                              }
                            },
                          },
                        }),
                        _c(
                          "label",
                          { staticClass: "mr10", attrs: { for: "bk1" } },
                          [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.013"))),
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.rf,
                              expression: "regParams.rf",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "bk2",
                            name: "bk",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.regParams.rf)
                              ? _vm._i(_vm.regParams.rf, null) > -1
                              : _vm._q(_vm.regParams.rf, "Y"),
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.regParams.rf,
                                $$el = $event.target,
                                $$c = $$el.checked ? "Y" : "N"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.regParams,
                                      "rf",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.regParams,
                                      "rf",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.regParams, "rf", $$c)
                              }
                            },
                          },
                        }),
                        _vm._m(1),
                      ]),
                      _c("span", { staticClass: "position_relative" }, [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon help",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.showRFinfo("Y")
                              },
                            },
                          },
                          [_vm._v("help")]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.RFinfo === "Y",
                                expression: "RFinfo === 'Y'",
                              },
                            ],
                            staticClass:
                              "tooltip_wrap position_absolute text_left",
                            staticStyle: {
                              width: "180px",
                              right: "0",
                              top: "24px",
                            },
                          },
                          [
                            _c("div", { staticClass: "cont" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "close",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.showRFinfo("N")
                                    },
                                  },
                                },
                                [_vm._v("close")]
                              ),
                              _c("ul", { staticClass: "bul_list_sm" }, [
                                _c("li", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("msg.VOSD100_M5.012"))
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm.$ekmtcCommon.isNotEmpty(_vm.userCtrCd)
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.parentInfo.yzrYn !== "Y",
                      expression: "parentInfo.yzrYn !== 'Y'",
                    },
                  ],
                  staticClass: "border_bottom_gray_box",
                },
                [
                  _c("p", { staticClass: "tit_label ml10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.clickMySchedule,
                          expression: "clickMySchedule",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "myschtit",
                        name: "myschtit",
                        "true-value": "Y",
                        "false-value": "N",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.clickMySchedule)
                          ? _vm._i(_vm.clickMySchedule, null) > -1
                          : _vm._q(_vm.clickMySchedule, "Y"),
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.clickMySchedule,
                            $$el = $event.target,
                            $$c = $$el.checked ? "Y" : "N"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.clickMySchedule = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.clickMySchedule = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.clickMySchedule = $$c
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "myschtit" } }, [
                      _c("span"),
                      _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.014"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.clickMySchedule === "Y",
                          expression: "clickMySchedule === 'Y'",
                        },
                      ],
                      staticClass: "ml10",
                      staticStyle: { display: "block" },
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "my_sche" },
                        _vm._l(_vm.myScheduleList, function (list, index) {
                          return _c("li", { key: index }, [
                            _c("input", {
                              attrs: {
                                type: "radio",
                                id: list.schId,
                                name: "mysch",
                              },
                              domProps: {
                                value: list.schId,
                                checked: list.schId === _vm.schId,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectMySchedule(list)
                                },
                              },
                            }),
                            _c("label", { attrs: { for: list.schId } }, [
                              _c("span"),
                              _vm._v(
                                _vm._s(list.polNm2) +
                                  " - " +
                                  _vm._s(list.podNm2)
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d_inline_block valign_top ml10 mt2",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "tbl_icon delete",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.fnDeleteMySchedule(
                                          list.schId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("delete")]
                                ),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.parentInfo.yzrYn !== "Y",
                  expression: "parentInfo.yzrYn !== 'Y'",
                },
              ],
              staticClass: "border_bottom_gray_box border_bottom_none",
            },
            [
              _c("p", { staticClass: "tit_label ml10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clickDetailSearch,
                      expression: "clickDetailSearch",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    id: "detailschtit",
                    name: "detailschtit",
                    "true-value": "Y",
                    "false-value": "N",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.clickDetailSearch)
                      ? _vm._i(_vm.clickDetailSearch, null) > -1
                      : _vm._q(_vm.clickDetailSearch, "Y"),
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.clickDetailSearch,
                        $$el = $event.target,
                        $$c = $$el.checked ? "Y" : "N"
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.clickDetailSearch = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.clickDetailSearch = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.clickDetailSearch = $$c
                      }
                    },
                  },
                }),
                _c("label", { attrs: { for: "detailschtit" } }, [
                  _c("span"),
                  _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.015"))),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.clickDetailSearch === "Y",
                      expression: "clickDetailSearch === 'Y'",
                    },
                  ],
                  staticClass: "ml23",
                  staticStyle: { display: "block" },
                },
                [
                  _c("table", { staticClass: "tbl_search" }, [
                    _vm._m(2),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { staticClass: "valign_top" }, [
                          _vm._v(
                            _vm._s(_vm.$t("msg.VOSD100.002")) +
                              " " +
                              _vm._s(_vm.$t("msg.VOSD100.039"))
                          ),
                        ]),
                        _c(
                          "td",
                          _vm._l(_vm.filterPolTrmlList, function (trml, idx) {
                            return _c(
                              "span",
                              { key: idx, staticClass: "d_block" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterPolTrmlCd,
                                      expression: "filterPolTrmlCd",
                                    },
                                  ],
                                  attrs: {
                                    id: "filterPolTrmlCd" + idx,
                                    type: "checkbox",
                                    disabled:
                                      _vm.rtnLegData.filterPolTrmlListSize ===
                                      1,
                                  },
                                  domProps: {
                                    value: trml.split("@@")[0],
                                    checked: Array.isArray(_vm.filterPolTrmlCd)
                                      ? _vm._i(
                                          _vm.filterPolTrmlCd,
                                          trml.split("@@")[0]
                                        ) > -1
                                      : _vm.filterPolTrmlCd,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.filterPolTrmlCd,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = trml.split("@@")[0],
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.filterPolTrmlCd = $$a.concat(
                                                [$$v]
                                              ))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.filterPolTrmlCd = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.filterPolTrmlCd = $$c
                                        }
                                      },
                                      function ($event) {
                                        return _vm.filterSearch()
                                      },
                                    ],
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "filterPolTrmlCd" + idx } },
                                  [
                                    _c("span"),
                                    _vm._v(
                                      _vm._s(trml.split("@@")[0]) +
                                        " " +
                                        _vm._s(trml.split("@@")[1])
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("th", { staticClass: "valign_top" }, [
                          _vm._v(
                            _vm._s(_vm.$t("msg.VOSD100.003")) +
                              " " +
                              _vm._s(_vm.$t("msg.VOSD100.039"))
                          ),
                        ]),
                        _c(
                          "td",
                          _vm._l(_vm.filterPodTrmlList, function (trml, idx) {
                            return _c(
                              "span",
                              { key: idx, staticClass: "d_block" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterPodTrmlCd,
                                      expression: "filterPodTrmlCd",
                                    },
                                  ],
                                  attrs: {
                                    id: "filterPodTrmlCd" + idx,
                                    type: "checkbox",
                                    disabled:
                                      _vm.rtnLegData.filterPodTrmlListSize ===
                                      1,
                                  },
                                  domProps: {
                                    value: trml.split("@@")[0],
                                    checked: Array.isArray(_vm.filterPodTrmlCd)
                                      ? _vm._i(
                                          _vm.filterPodTrmlCd,
                                          trml.split("@@")[0]
                                        ) > -1
                                      : _vm.filterPodTrmlCd,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.filterPodTrmlCd,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = trml.split("@@")[0],
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.filterPodTrmlCd = $$a.concat(
                                                [$$v]
                                              ))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.filterPodTrmlCd = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.filterPodTrmlCd = $$c
                                        }
                                      },
                                      function ($event) {
                                        return _vm.filterSearch()
                                      },
                                    ],
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "filterPodTrmlCd" + idx } },
                                  [
                                    _c("span"),
                                    _vm._v(
                                      _vm._s(trml.split("@@")[0]) +
                                        " " +
                                        _vm._s(trml.split("@@")[1])
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("tr", [
                        _c("th", { staticClass: "valign_top" }, [
                          _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.006"))),
                        ]),
                        _c(
                          "td",
                          { attrs: { colspan: "3" } },
                          _vm._l(_vm.filterRteCdList, function (trml, idx) {
                            return _c(
                              "span",
                              {
                                key: idx,
                                staticStyle: {
                                  width: "30%",
                                  display: "inline-block",
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterRteCd,
                                      expression: "filterRteCd",
                                    },
                                  ],
                                  attrs: {
                                    id: "filterRteCd" + idx,
                                    type: "checkbox",
                                    disabled: _vm.filterRteCdList.length === 1,
                                  },
                                  domProps: {
                                    value: trml.split("@@")[0],
                                    checked: Array.isArray(_vm.filterRteCd)
                                      ? _vm._i(
                                          _vm.filterRteCd,
                                          trml.split("@@")[0]
                                        ) > -1
                                      : _vm.filterRteCd,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.filterRteCd,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = trml.split("@@")[0],
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.filterRteCd = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.filterRteCd = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.filterRteCd = $$c
                                        }
                                      },
                                      function ($event) {
                                        return _vm.filterSearch()
                                      },
                                    ],
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "filterRteCd" + idx } },
                                  [
                                    _c("span"),
                                    _vm._v(
                                      _vm._s(trml.split("@@")[0]) +
                                        " " +
                                        _vm._s(trml.split("@@")[1])
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.056")))]),
                        _c("td", [
                          _c("span", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.vslType01,
                                  expression: "regParams.vslType01",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "oper1",
                                name: "oper",
                                "true-value": "01",
                                "false-value": "N",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.regParams.vslType01)
                                  ? _vm._i(_vm.regParams.vslType01, null) > -1
                                  : _vm._q(_vm.regParams.vslType01, "01"),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.regParams.vslType01,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "01" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.regParams,
                                          "vslType01",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.regParams,
                                          "vslType01",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.regParams, "vslType01", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._m(3),
                          ]),
                          _c("span", { staticClass: "ml20" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.vslType03,
                                  expression: "regParams.vslType03",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "oper2",
                                name: "oper",
                                "true-value": "03",
                                "false-value": "N",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.regParams.vslType03)
                                  ? _vm._i(_vm.regParams.vslType03, null) > -1
                                  : _vm._q(_vm.regParams.vslType03, "03"),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.regParams.vslType03,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "03" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.regParams,
                                          "vslType03",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.regParams,
                                          "vslType03",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.regParams, "vslType03", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._m(4),
                          ]),
                        ]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.057")))]),
                        _c("td", [
                          _c("span", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.filterDirect,
                                  expression: "regParams.filterDirect",
                                },
                              ],
                              attrs: {
                                id: "filterDirect",
                                type: "checkbox",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.regParams.filterDirect
                                )
                                  ? _vm._i(_vm.regParams.filterDirect, null) >
                                    -1
                                  : _vm._q(_vm.regParams.filterDirect, "Y"),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.regParams.filterDirect,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "Y" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.regParams,
                                          "filterDirect",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.regParams,
                                          "filterDirect",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.regParams, "filterDirect", $$c)
                                  }
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "filterDirect" } }, [
                              _c("span"),
                              _vm._v(_vm._s(_vm.$t("msg.VOSD100.060"))),
                            ]),
                          ]),
                          _c("span", { staticClass: "ml20" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.filterTS,
                                  expression: "regParams.filterTS",
                                },
                              ],
                              attrs: {
                                id: "filterTS",
                                type: "checkbox",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.regParams.filterTS)
                                  ? _vm._i(_vm.regParams.filterTS, null) > -1
                                  : _vm._q(_vm.regParams.filterTS, "Y"),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.regParams.filterTS,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "Y" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.regParams,
                                          "filterTS",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.regParams,
                                          "filterTS",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.regParams, "filterTS", $$c)
                                  }
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "filterTS" } }, [
                              _c("span"),
                              _vm._v(_vm._s(_vm.$t("msg.VOSD100.061"))),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.058")))]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _c(
                              "span",
                              { staticClass: "dv col_7 position_relative" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.unnoDescTxt,
                                      expression: "unnoDescTxt",
                                    },
                                  ],
                                  attrs: { type: "text", disabled: "disabled" },
                                  domProps: { value: _vm.unnoDescTxt },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.unnoDescTxt = $event.target.value
                                    },
                                  },
                                }),
                                _vm.$ekmtcCommon.isNotEmpty(_vm.unnoDescTxt)
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "btn_txt_reset",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.resetUnnoTxt()
                                          },
                                        },
                                      },
                                      [_vm._v("X")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c("span", { staticClass: "sp" }),
                            _c("span", { staticClass: "dv" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "button sm",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openUnnoPopup.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.VOSD100.059")))]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("art.CMBA660.011")))]),
                        _c(
                          "td",
                          [
                            _c("e-auto-complete-commodity", {
                              staticClass: "col_8",
                              attrs: {
                                id: "scheSearchCommodity",
                                "ctr-cd": _vm.ctrCd,
                                "cd-nm": _vm.commodityNm,
                              },
                              on: { change: _vm.changeCommodity },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.parentInfo.yzrYn !== "Y",
                  expression: "parentInfo.yzrYn !== 'Y'",
                },
              ],
              staticClass: "position_relative",
            },
            [
              _c(
                "span",
                {
                  staticClass: "position_absolute",
                  staticStyle: { right: "0", bottom: "5px" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "button blue sh",
                      on: {
                        click: function ($event) {
                          return _vm.clickChk(_vm.searchYear, _vm.searchMonth)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00120")))]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "content_box mt10" }, [
      _c("div", { staticClass: "wrap_schedule" }, [
        _c("div", { staticClass: "schedule_top" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.afterSearch === "Y",
                  expression: "afterSearch === 'Y'",
                },
              ],
              staticClass: "sail_area",
            },
            [
              _c("p", { staticClass: "port" }, [
                _c("span", [_vm._v(_vm._s(_vm.startPlcNameSel[0]))]),
                _c("span", [_vm._v(_vm._s(_vm.destPlcNameSel[0]))]),
                _vm.$ekmtcCommon.isNotEmpty(_vm.userCtrCd)
                  ? _c("span", { staticClass: "icon" }, [
                      _vm.myScheduleOrNot === "N"
                        ? _c("div", [
                            _c("button", {
                              staticClass: "tbl_icon myschedule",
                              on: {
                                click: function ($event) {
                                  return _vm.fnAddMySchedule(_vm.rtnLegData)
                                },
                              },
                            }),
                          ])
                        : _c("div", [
                            _c("button", {
                              staticClass: "tbl_icon myschedule on",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteMyScheduleStar(_vm.schId)
                                },
                              },
                            }),
                          ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.rtnLegData.voyCntSum !== 0,
                      expression: "rtnLegData.voyCntSum !== 0",
                    },
                  ],
                },
                [
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.016")))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.rtnLegData.monCnt)),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.017")))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.rtnLegData.tueCnt)),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.018")))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.rtnLegData.wedCnt)),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.019")))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.rtnLegData.thuCnt)),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.020")))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.rtnLegData.friCnt)),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.021")))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.rtnLegData.satCnt)),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.022")))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.rtnLegData.sunCnt)),
                    ]),
                  ]),
                  _c("li", { staticClass: "week" }, [
                    _c("span", { staticClass: "week" }, [
                      _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.031"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.rtnLegData.voyCntSum)),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.023")))]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "btn_area" }, [
                _c("span", [
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("EmailSchedulePop")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.VOSD100.040")))]
                  ),
                ]),
                _c("span", [
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      on: {
                        click: function ($event) {
                          return _vm.excelDownload()
                        },
                      },
                    },
                    [_vm._v("Download")]
                  ),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "date_area" }, [
            _c(
              "div",
              {
                staticClass: "remark",
                style: _vm.kmtcPremiumUserYn === "Y" ? "" : "width: 25%;",
              },
              [
                _c("span", { staticClass: "finish" }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBL100_M1.017"))),
                ]),
                _c("span", { staticClass: "possible" }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBL100_M1.013"))),
                ]),
                _c("span", { staticClass: "after" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("msg.VOSD100_M5.125", { bkgNotCnt: _vm.bkgNotCnt })
                    )
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "date" }, [
              _c("span", [
                _c(
                  "a",
                  {
                    staticClass: "prev",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnChangeCalendar(_vm.prevMonth)
                      },
                    },
                  },
                  [_vm._v("prev")]
                ),
              ]),
              _c("span", { staticClass: "year" }, [
                _vm._v(_vm._s(_vm.currentYear)),
              ]),
              _c("span", { staticClass: "month" }, [
                _vm._v(_vm._s(_vm.currentMonth)),
              ]),
              _c("span", [
                _c(
                  "a",
                  {
                    staticClass: "next",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnChangeCalendar(_vm.nextMonth)
                      },
                    },
                  },
                  [_vm._v("next")]
                ),
              ]),
              _c("span", [
                _vm._v("    "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.kmtcPremiumUserYn === "Y",
                      expression: "kmtcPremiumUserYn === 'Y'",
                    },
                  ],
                  staticStyle: { "vertical-align": "middle" },
                  attrs: {
                    src: require("../../assets/images/common/icon_premium.png"),
                  },
                }),
              ]),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.kmtcPremiumUserYn === "Y",
                      expression: "kmtcPremiumUserYn === 'Y'",
                    },
                  ],
                  staticStyle: {
                    "font-size": "15px",
                    "vertical-align": "middle",
                    color: "#0080db",
                  },
                },
                [
                  _vm._v("   :   선복보장 PREMIUM 부킹 가능 스케줄 "),
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      staticStyle: {
                        "text-indent": "0px",
                        width: "110px",
                        "line-height": "22px",
                      },
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.pdfDownload(
                            "KMTC_ON_PREMIUM_FAQ_240717.pdf"
                          )
                        },
                      },
                    },
                    [_vm._v("PREMIUM 부킹 FAQ")]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "memu" }, [
              _c("a", {
                class: _vm.calendarOrList === "C" ? "month on" : "month off",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.viewCalendar("C")
                  },
                },
              }),
              _c("a", {
                class: _vm.calendarOrList === "L" ? "list on" : "list off",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.viewCalendar("L")
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.calendarOrList === "C",
                expression: "calendarOrList === 'C'",
              },
            ],
            staticClass: "sc_calender_type",
          },
          [
            _c("table", { staticClass: "tbl_schedule" }, [
              _vm._m(5),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.022")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.016")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.017")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.018")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.019")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.020")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.021")))]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.dates, function (date, idx) {
                  return _c(
                    "tr",
                    { key: idx },
                    _vm._l(date, function (day, secondIdx) {
                      return _c(
                        "td",
                        { key: secondIdx },
                        [
                          _vm._l(_vm.holidayList, function (holiday, dayIdx) {
                            return _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.holidayList.length !== 0,
                                    expression: "holidayList.length !== 0",
                                  },
                                ],
                                key: dayIdx,
                              },
                              [
                                day == holiday.bascDt2 &&
                                holiday.hldNm === "NOT"
                                  ? _c("div", [_vm._v(_vm._s(day))])
                                  : day == holiday.bascDt2 &&
                                    holiday.hldNm !== "NOT"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "font",
                                          { attrs: { color: "#d81d6c" } },
                                          [_vm._v(_vm._s(day))]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.holidayList.length === 0,
                                  expression: "holidayList.length === 0",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(day))]
                          ),
                          _vm.bound === "O"
                            ? _c("div", [
                                day < 10
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.listSchedule,
                                        function (schedule, schIdx) {
                                          return _c("div", { key: schIdx }, [
                                            _vm.todayStr2 + "0" + day ===
                                              schedule.etd &&
                                            schedule.filterChkYN === "Y"
                                              ? _c("div", [
                                                  schedule.bkgClose === "Y"
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.showBkg ===
                                                                  "N",
                                                                expression:
                                                                  "showBkg === 'N'",
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "finish",
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    class:
                                                                      _vm.urlOrNot
                                                                        ? "action"
                                                                        : "",
                                                                    attrs: {
                                                                      href: "#",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.fnDetailLayer2(
                                                                            schedule
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        schedule.vslNm
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v("  "),
                                                                _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.kmtcPremiumUserYn ===
                                                                          "Y" &&
                                                                        schedule.kmtcPremiumLineYn ===
                                                                          "Y",
                                                                      expression:
                                                                        "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                                                    },
                                                                  ],
                                                                  staticStyle: {
                                                                    "vertical-align":
                                                                      "bottom",
                                                                  },
                                                                  attrs: {
                                                                    src: require("../../assets/images/common/icon_premium.png"),
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : schedule.bkgClose == "A"
                                                    ? _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v("  "),
                                                            _c("img", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.kmtcPremiumUserYn ===
                                                                      "Y" &&
                                                                    schedule.kmtcPremiumLineYn ===
                                                                      "Y",
                                                                  expression:
                                                                    "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "bottom",
                                                              },
                                                              attrs: {
                                                                src: require("../../assets/images/common/icon_premium.png"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ])
                                                    : schedule.bkgClose === "M"
                                                    ? _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "after",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v("  "),
                                                            _c("img", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.kmtcPremiumUserYn ===
                                                                      "Y" &&
                                                                    schedule.kmtcPremiumLineYn ===
                                                                      "Y",
                                                                  expression:
                                                                    "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "bottom",
                                                              },
                                                              attrs: {
                                                                src: require("../../assets/images/common/icon_premium.png"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ])
                                                    : _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v("  "),
                                                            _c("img", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.kmtcPremiumUserYn ===
                                                                      "Y" &&
                                                                    schedule.kmtcPremiumLineYn ===
                                                                      "Y",
                                                                  expression:
                                                                    "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "bottom",
                                                              },
                                                              attrs: {
                                                                src: require("../../assets/images/common/icon_premium.png"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]),
                                                ])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _c(
                                      "div",
                                      _vm._l(
                                        _vm.listSchedule,
                                        function (schedule, schIdx) {
                                          return _c("div", { key: schIdx }, [
                                            _vm.todayStr2 + day ===
                                              schedule.etd &&
                                            schedule.filterChkYN === "Y"
                                              ? _c("div", [
                                                  schedule.bkgClose === "Y"
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.showBkg ===
                                                                  "N",
                                                                expression:
                                                                  "showBkg === 'N'",
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "finish",
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    class:
                                                                      _vm.urlOrNot
                                                                        ? "action"
                                                                        : "",
                                                                    attrs: {
                                                                      href: "#",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.fnDetailLayer2(
                                                                            schedule
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        schedule.vslNm
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v("  "),
                                                                _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.kmtcPremiumUserYn ===
                                                                          "Y" &&
                                                                        schedule.kmtcPremiumLineYn ===
                                                                          "Y",
                                                                      expression:
                                                                        "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                                                    },
                                                                  ],
                                                                  staticStyle: {
                                                                    "vertical-align":
                                                                      "bottom",
                                                                  },
                                                                  attrs: {
                                                                    src: require("../../assets/images/common/icon_premium.png"),
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : schedule.bkgClose == "A"
                                                    ? _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v("  "),
                                                            _c("img", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.kmtcPremiumUserYn ===
                                                                      "Y" &&
                                                                    schedule.kmtcPremiumLineYn ===
                                                                      "Y",
                                                                  expression:
                                                                    "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "bottom",
                                                              },
                                                              attrs: {
                                                                src: require("../../assets/images/common/icon_premium.png"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ])
                                                    : schedule.bkgClose === "M"
                                                    ? _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "after",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v("  "),
                                                            _c("img", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.kmtcPremiumUserYn ===
                                                                      "Y" &&
                                                                    schedule.kmtcPremiumLineYn ===
                                                                      "Y",
                                                                  expression:
                                                                    "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "bottom",
                                                              },
                                                              attrs: {
                                                                src: require("../../assets/images/common/icon_premium.png"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ])
                                                    : _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v("  "),
                                                            _c("img", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.kmtcPremiumUserYn ===
                                                                      "Y" &&
                                                                    schedule.kmtcPremiumLineYn ===
                                                                      "Y",
                                                                  expression:
                                                                    "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "bottom",
                                                              },
                                                              attrs: {
                                                                src: require("../../assets/images/common/icon_premium.png"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]),
                                                ])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                              ])
                            : _vm._e(),
                          _vm.bound === "I"
                            ? _c("div", [
                                day < 10
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.listSchedule,
                                        function (schedule, schIdx) {
                                          return _c("div", { key: schIdx }, [
                                            _vm.todayStr2 + "0" + day ===
                                              schedule.eta &&
                                            schedule.filterChkYN === "Y"
                                              ? _c("div", [
                                                  schedule.bkgClose === "Y"
                                                    ? _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : schedule.bkgClose == "A"
                                                    ? _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : schedule.bkgClose === "M"
                                                    ? _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                ])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _c(
                                      "div",
                                      _vm._l(
                                        _vm.listSchedule,
                                        function (schedule, schIdx) {
                                          return _c("div", { key: schIdx }, [
                                            _vm.todayStr2 + day ===
                                              schedule.eta &&
                                            schedule.filterChkYN === "Y"
                                              ? _c("div", [
                                                  schedule.bkgClose === "Y"
                                                    ? _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : schedule.bkgClose == "A"
                                                    ? _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : schedule.bkgClose === "M"
                                                    ? _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _c("div", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "possible",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                class:
                                                                  _vm.urlOrNot
                                                                    ? "action"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.fnDetailLayer2(
                                                                        schedule
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.vslNm
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                ])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.calendarOrList === "L",
                expression: "calendarOrList === 'L'",
              },
            ],
            staticClass: "sc_list_type div_detailLayer",
          },
          [
            _c(
              "table",
              { staticClass: "tbl_col" },
              [
                _c("colgroup", [
                  _c("col", { staticStyle: { width: "7%" } }),
                  _c("col", { staticStyle: { width: "15%" } }),
                  _c("col", { staticStyle: { width: "auto" } }),
                  _c("col", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bound === "O",
                        expression: "bound === 'O'",
                      },
                    ],
                    staticStyle: { width: "24%" },
                  }),
                  _c("col", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bound === "I",
                        expression: "bound === 'I'",
                      },
                    ],
                    staticStyle: { width: "34%" },
                  }),
                  _c("col", { staticStyle: { width: "10%" } }),
                  _c("col", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bound === "O",
                        expression: "bound === 'O'",
                      },
                    ],
                    staticStyle: { width: "12%" },
                  }),
                  _c("col", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bound === "O",
                        expression: "bound === 'O'",
                      },
                    ],
                    staticStyle: { width: "8%" },
                  }),
                ]),
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CMBA100.00154"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.005"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.VOSD100.002"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.VOSD100.003"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL100_M1.015"))),
                    ]),
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bound === "O",
                            expression: "bound === 'O'",
                          },
                        ],
                        attrs: { scope: "col" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("msg.VOSD100_M4.009")) +
                            "/" +
                            _vm._s(_vm.$t("msg.VOSD100_M4.010"))
                        ),
                      ]
                    ),
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bound === "O",
                            expression: "bound === 'O'",
                          },
                        ],
                        attrs: { scope: "col" },
                      },
                      [_vm._v("Booking")]
                    ),
                  ]),
                ]),
                _vm._l(_vm.listSchedule4List, function (schedule, schIdx) {
                  return _c("tbody", { key: schIdx }, [
                    schedule.ts === "Y" &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c("td", { attrs: { rowspan: "10" } }, [
                            _vm._v("T/S"),
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "text_left",
                              attrs: { rowspan: "2" },
                            },
                            [
                              schedule.vslNm1 !== "Rail" &&
                              schedule.vslNm1 !== "Truck"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "td_underline",
                                      attrs: { href: "#none" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "VesselScheduleInfoPop",
                                            1,
                                            schedule
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(schedule.vslNm1) +
                                          " " +
                                          _vm._s(schedule.voyNo)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              schedule.vslNm1 === "Rail" ||
                              schedule.vslNm1 === "Truck"
                                ? _c("a", [
                                    _vm._v(
                                      _vm._s(schedule.vslNm1) +
                                        " " +
                                        _vm._s(schedule.voyNo)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              _vm.$ekmtcCommon.isNotEmpty(schedule.rteCd)
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "td_underline",
                                      attrs: { href: "#none" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "RoutePop",
                                            schedule.rteCd,
                                            schedule.vslNm1
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "(Route : " +
                                          _vm._s(schedule.rteCd) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v("  "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.kmtcPremiumUserYn === "Y" &&
                                      schedule.kmtcPremiumLineYn === "Y",
                                    expression:
                                      "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                  },
                                ],
                                staticStyle: { "vertical-align": "top" },
                                attrs: {
                                  src: require("../../assets/images/common/icon_premium.png"),
                                },
                              }),
                            ]
                          ),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "td_underline",
                                attrs: { href: "#none" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup(
                                      "PortTerminalCYPop",
                                      schedule.pol1,
                                      schedule.polTrml1
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(schedule.polNm))]
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "td_underline",
                                attrs: { href: "#none" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup(
                                      "PortTerminalCYPop",
                                      schedule.pod1,
                                      schedule.podTrml1
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(_vm._s(schedule.pol2Nm) + "("),
                                _c("span", { staticClass: "color_red" }, [
                                  _vm._v("T/S"),
                                ]),
                                _vm._v(")"),
                              ]
                            ),
                          ]),
                          _c("td", { attrs: { rowspan: "2" } }, [
                            _c("ul", [
                              _c("li", [_vm._v(_vm._s(schedule.transitTime1))]),
                            ]),
                          ]),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.bound === "O",
                                  expression: "bound === 'O'",
                                },
                              ],
                              attrs: { rowspan: schedule.tsDegree * 2 + 1 },
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.changeDateTime2Display(
                                        schedule.bkgDocCls,
                                        _vm.lang
                                      )
                                    )
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.changeDateTime2Display(
                                        schedule.bkgCgoCls,
                                        _vm.lang
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.bound === "O",
                                  expression: "bound === 'O'",
                                },
                              ],
                              staticClass: "color_red",
                              attrs: { rowspan: schedule.tsDegree * 2 + 1 },
                            },
                            [
                              schedule.bkgClose === "Y"
                                ? _c("div", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("msg.CSBL100_M1.017"))
                                    ),
                                  ])
                                : schedule.bkgClose !== "M"
                                ? _c("div", [
                                    _c("ul", [
                                      _c("li", { staticClass: "mt5" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "button sm blue",
                                            on: {
                                              click: function ($event) {
                                                return _vm.bookingBooking4List(
                                                  schedule
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Booking")]
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _c("div", [_vm._v(" - ")]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    schedule.ts === "Y" &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c("td", { staticClass: "bor-left" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(_vm._s(_vm.$t("msg.VOSD100_M2.003"))),
                                _vm._v(
                                  " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.polEtb,
                                        schedule.polEtbTm,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _vm._v(_vm._s(_vm.$t("msg.VOSD100_M2.004"))),
                                _vm._v(
                                  " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.etd,
                                        schedule.etdTm,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "td_underline",
                                    attrs: { href: "#none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openPopup(
                                          "PortTerminalCYPop",
                                          schedule.pol1,
                                          schedule.polTrml1
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.otrmlCd,
                                          schedule.otrmlNm
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("td", [
                            _c("ul", [
                              _c("li", [
                                _vm._v(_vm._s(_vm.$t("msg.VOSD100_M2.003"))),
                                _vm._v(
                                  " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.podEtd1,
                                        schedule.etaTm1,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "td_underline",
                                    attrs: { href: "#none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openPopup(
                                          "PortTerminalCYPop",
                                          schedule.pod1,
                                          schedule.podTrml1
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.podTrml1,
                                          schedule.podTrml1Nm
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    schedule.ts === "Y" &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "bor-left",
                              attrs: { colspan: "3" },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.069")))]
                          ),
                          _c("td", [_vm._v(_vm._s(schedule.transitTSTime2))]),
                        ])
                      : _vm._e(),
                    schedule.ts === "Y" &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text_left bor-left",
                              attrs: { rowspan: "2" },
                            },
                            [
                              schedule.vslNm2 !== "Rail" &&
                              schedule.vslNm2 !== "Truck"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "td_underline",
                                      attrs: { href: "#none" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "VesselScheduleInfoPop",
                                            2,
                                            schedule
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(schedule.vslNm2) +
                                          " " +
                                          _vm._s(schedule.voyNo2)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              schedule.vslNm2 === "Rail" ||
                              schedule.vslNm2 === "Truck"
                                ? _c("a", [
                                    _vm._v(
                                      _vm._s(schedule.vslNm2) +
                                        " " +
                                        _vm._s(schedule.voyNo2)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              _vm.$ekmtcCommon.isNotEmpty(schedule.rteCd2)
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "td_underline",
                                      attrs: { href: "#none" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "RoutePop",
                                            schedule.rteCd2,
                                            schedule.vslNm2
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "(Route : " +
                                          _vm._s(schedule.rteCd2) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v("  "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.kmtcPremiumUserYn === "Y" &&
                                      schedule.kmtcPremiumLineYn === "Y",
                                    expression:
                                      "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                  },
                                ],
                                staticStyle: { "vertical-align": "top" },
                                attrs: {
                                  src: require("../../assets/images/common/icon_premium.png"),
                                },
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        schedule.tsDegree === "2" &&
                                        _vm.vessel2ndButton === "Y",
                                      expression:
                                        "schedule.tsDegree === '2' && vessel2ndButton === 'Y'",
                                    },
                                  ],
                                  staticClass: "mt5",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button sm",
                                      on: {
                                        click: function ($event) {
                                          return _vm.change2ndVessel4List(
                                            schedule,
                                            schIdx
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.VOSD100_M5.027"))
                                      ),
                                      _c("span", {
                                        staticClass: "btn_icon icoup",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "td_underline",
                                attrs: { href: "#none" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup(
                                      "PortTerminalCYPop",
                                      schedule.pol2,
                                      schedule.polTrml2
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(_vm._s(schedule.pod1Nm) + "("),
                                _c("span", { staticClass: "color_red" }, [
                                  _vm._v("T/S"),
                                ]),
                                _vm._v(")"),
                              ]
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "td_underline",
                                attrs: { href: "#none" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup(
                                      "PortTerminalCYPop",
                                      schedule.pod2,
                                      schedule.podTrml2
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(schedule.pod2Nm))]
                            ),
                          ]),
                          _c("td", { attrs: { rowspan: "2" } }, [
                            _c("ul", [
                              _c("li", [_vm._v(_vm._s(schedule.transitTime2))]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    schedule.ts === "Y" &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c("td", { staticClass: "bor-left" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.VOSD100_M2.004")) +
                                    " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.polEtd2,
                                        schedule.etdTm2,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "td_underline",
                                    attrs: { href: "#none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openPopup(
                                          "PortTerminalCYPop",
                                          schedule.pol2,
                                          schedule.polTrml2
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.polTrml2,
                                          schedule.polTrml2Nm
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("td", [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.VOSD100_M2.003")) +
                                    " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.podEtd2,
                                        schedule.etaTm2,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "td_underline",
                                    attrs: { href: "#none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openPopup(
                                          "PortTerminalCYPop",
                                          schedule.pod2,
                                          schedule.podTrml2
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.podTrml2,
                                          schedule.podTrml2Nm
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    schedule.ts === "Y" &&
                    schedule.tsDegree >= 3 &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text_left bor-left",
                              attrs: { rowspan: "2" },
                            },
                            [
                              _vm._v(" " + _vm._s(schedule.rteCd3) + " "),
                              schedule.vslNm3 !== "Rail" &&
                              schedule.vslNm3 !== "Truck"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "td_underline",
                                      attrs: { href: "#none" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "VesselScheduleInfoPop",
                                            3,
                                            schedule
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(schedule.vslNm3) +
                                          " " +
                                          _vm._s(schedule.voyNo3)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              schedule.vslNm3 === "Rail" ||
                              schedule.vslNm3 === "Truck"
                                ? _c("a", [
                                    _vm._v(
                                      _vm._s(schedule.vslNm3) +
                                        " " +
                                        _vm._s(schedule.voyNo3)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              _vm.$ekmtcCommon.isNotEmpty(schedule.rteCd3)
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "td_underline",
                                      attrs: { href: "#none" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "RoutePop",
                                            schedule.rteCd3,
                                            schedule.vslNm3
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "(Route : " +
                                          _vm._s(schedule.rteCd3) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v("  "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.kmtcPremiumUserYn === "Y" &&
                                      schedule.kmtcPremiumLineYn === "Y",
                                    expression:
                                      "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                  },
                                ],
                                staticStyle: { "vertical-align": "top" },
                                attrs: {
                                  src: require("../../assets/images/common/icon_premium.png"),
                                },
                              }),
                            ]
                          ),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "td_underline",
                                attrs: { href: "#none" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup(
                                      "PortTerminalCYPop",
                                      schedule.pol3,
                                      schedule.polTrml3
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(schedule.pod2Nm))]
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "td_underline",
                                attrs: { href: "#none" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup(
                                      "PortTerminalCYPop",
                                      schedule.pod3,
                                      schedule.podTrml3
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(schedule.pod3Nm))]
                            ),
                          ]),
                          _c("td", { attrs: { rowspan: "2" } }, [
                            _c("ul", [
                              _c("li", [_vm._v(_vm._s(schedule.transitTime3))]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    schedule.ts === "Y" &&
                    schedule.tsDegree >= 3 &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c("td", { staticClass: "bor-left" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.VOSD100_M2.004")) +
                                    " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.polEtd3,
                                        schedule.etdTm3,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "td_underline",
                                    attrs: { href: "#none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openPopup(
                                          "PortTerminalCYPop",
                                          schedule.pol3,
                                          schedule.polTrml3
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.polTrml3,
                                          schedule.polTrml3Nm
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("td", [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.VOSD100_M2.003")) +
                                    " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.podEtd3,
                                        schedule.etaTm3,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "td_underline",
                                    attrs: { href: "#none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openPopup(
                                          "PortTerminalCYPop",
                                          schedule.pod3,
                                          schedule.podTrml3
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.podTrml3,
                                          schedule.podTrml3Nm
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    schedule.ts === "Y" &&
                    schedule.tsDegree >= 4 &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text_left bor-left",
                              attrs: { rowspan: "2" },
                            },
                            [
                              schedule.vslNm4 !== "Rail" &&
                              schedule.vslNm4 !== "Truck"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "td_underline",
                                      attrs: { href: "#none" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "VesselScheduleInfoPop",
                                            4,
                                            schedule
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(schedule.vslNm4) +
                                          " " +
                                          _vm._s(schedule.voyNo4)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              schedule.vslNm4 === "Rail" ||
                              schedule.vslNm4 === "Truck"
                                ? _c("a", [
                                    _vm._v(
                                      _vm._s(schedule.vslNm4) +
                                        " " +
                                        _vm._s(schedule.voyNo4)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              _vm.$ekmtcCommon.isNotEmpty(schedule.rteCd4)
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "td_underline",
                                      attrs: { href: "#none" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "RoutePop",
                                            schedule.rteCd4,
                                            schedule.vslNm4
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "(Route : " +
                                          _vm._s(schedule.rteCd4) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v("  "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.kmtcPremiumUserYn === "Y" &&
                                      schedule.kmtcPremiumLineYn === "Y",
                                    expression:
                                      "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                  },
                                ],
                                staticStyle: { "vertical-align": "top" },
                                attrs: {
                                  src: require("../../assets/images/common/icon_premium.png"),
                                },
                              }),
                            ]
                          ),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "td_underline",
                                attrs: { href: "#none" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup(
                                      "PortTerminalCYPop",
                                      schedule.pol4,
                                      schedule.polTrml4
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(schedule.pod3Nm))]
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "td_underline",
                                attrs: { href: "#none" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup(
                                      "PortTerminalCYPop",
                                      schedule.pod4,
                                      schedule.podTrml4
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(schedule.pod4Nm))]
                            ),
                          ]),
                          _c("td", { attrs: { rowspan: "2" } }, [
                            _c("ul", [
                              _c("li", [_vm._v(_vm._s(schedule.transitTime4))]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    schedule.ts === "Y" &&
                    schedule.tsDegree >= 4 &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c("td", { staticClass: "bor-left" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.VOSD100_M2.004")) +
                                    " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.polEtd4,
                                        schedule.etdTm4,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "td_underline",
                                    attrs: { href: "#none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openPopup(
                                          "PortTerminalCYPop",
                                          schedule.pol4,
                                          schedule.polTrml4
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.polTrml4,
                                          schedule.polTrml4Nm
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("td", [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.VOSD100_M2.003")) +
                                    " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.podEtd4,
                                        schedule.etaTm4,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "td_underline",
                                    attrs: { href: "#none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openPopup(
                                          "PortTerminalCYPop",
                                          schedule.pod4,
                                          schedule.podTrml4
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.podTrml4,
                                          schedule.podTrml4Nm
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.show2ndVessel === "Y" &&
                              _vm.calendarOrList === "L" &&
                              schIdx === _vm.listIndexNo,
                            expression:
                              "show2ndVessel === 'Y' && calendarOrList === 'L' && schIdx === listIndexNo",
                          },
                        ],
                        attrs: { id: "vessel2nd" + schIdx },
                      },
                      [
                        _c(
                          "td",
                          {
                            staticClass: "bor-left pd0",
                            attrs: { colspan: "6" },
                          },
                          [
                            _c("div", { staticClass: "inner_table" }, [
                              _c("div", { staticClass: "port_info" }, [
                                _c("p", { staticClass: "port t2" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(schedule.polNm.split(",")[0])
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(schedule.podNm.split(",")[0])
                                    ),
                                  ]),
                                ]),
                                _c("p", { staticClass: "txt_desc none" }, [
                                  _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.028"))),
                                ]),
                                _c("div", { staticClass: "btn_port_cancel" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button sm gray",
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancel2ndVessel4List(
                                            schIdx
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.VOSD100_M5.029"))
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "table",
                                { staticClass: "tbl_col t2 tac mt10" },
                                [
                                  _vm._m(6, true),
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("menu.MENU09.060"))
                                        ),
                                      ]),
                                      _c("th", [_vm._v("ETD")]),
                                      _c("th", [_vm._v("ETA")]),
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBL100_M1.015"))
                                        ),
                                      ]),
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBL200.075"))
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.vessel2ndList,
                                      function (list, idx) {
                                        return _c("tr", { key: idx }, [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(list.vslNm) +
                                                " " +
                                                _vm._s(list.voyNo)
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(list.etdStrDT)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(list.etaStrDT)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(list.transitTime)),
                                          ]),
                                          _c("td", [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "button gray sm",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.select2ndVessel4List(
                                                      list,
                                                      schIdx
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("msg.CSBL200.075")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    schedule.ts === "Y" &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "bor-left pd0",
                              attrs: { colspan: "6" },
                            },
                            [
                              _c("P", { staticClass: "mt5 text_right" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.VOSD100_M5.110")) +
                                    " : " +
                                    _vm._s(schedule.transitTime)
                                ),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    schedule.ts !== "Y" &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c("td", { attrs: { rowspan: "2" } }, [
                            _vm._v("Direct"),
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "text_left",
                              attrs: { rowspan: "2" },
                            },
                            [
                              schedule.vslNm !== "Rail" &&
                              schedule.vslNm !== "Truck"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "td_underline",
                                      attrs: { href: "#none" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "VesselScheduleInfoPop",
                                            1,
                                            schedule
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(schedule.vslNm) +
                                          " " +
                                          _vm._s(schedule.voyNo)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              schedule.vslNm === "Rail" ||
                              schedule.vslNm === "Truck"
                                ? _c("a", [
                                    _vm._v(
                                      _vm._s(schedule.vslNm) +
                                        " " +
                                        _vm._s(schedule.voyNo)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              _vm.$ekmtcCommon.isNotEmpty(schedule.rteCd)
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "td_underline",
                                      attrs: { href: "#none" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "RoutePop",
                                            schedule.rteCd,
                                            schedule.vslNm
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "(Route : " +
                                          _vm._s(schedule.rteCd) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.kmtcPremiumUserYn === "Y" &&
                                      schedule.kmtcPremiumLineYn === "Y",
                                    expression:
                                      "kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'",
                                  },
                                ],
                                staticStyle: { "vertical-align": "top" },
                                attrs: {
                                  src: require("../../assets/images/common/icon_premium.png"),
                                },
                              }),
                            ]
                          ),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "td_underline",
                                attrs: { href: "#none" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup(
                                      "PortTerminalCYPop",
                                      schedule.pol,
                                      schedule.polTml
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(schedule.polNm))]
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "td_underline",
                                attrs: { href: "#none" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup(
                                      "PortTerminalCYPop",
                                      schedule.pod,
                                      schedule.podTml
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(schedule.podNm))]
                            ),
                          ]),
                          _c("td", { attrs: { rowspan: "2" } }, [
                            _c("ul", [
                              _c("li", [_vm._v(_vm._s(schedule.transitTime))]),
                            ]),
                          ]),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.bound === "O",
                                  expression: "bound === 'O'",
                                },
                              ],
                              attrs: { rowspan: "2" },
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.changeDateTime2Display(
                                        schedule.bkgDocCls,
                                        _vm.lang
                                      )
                                    )
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.changeDateTime2Display(
                                        schedule.bkgCgoCls,
                                        _vm.lang
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.bound === "O",
                                  expression: "bound === 'O'",
                                },
                              ],
                              staticClass: "color_red",
                              attrs: { rowspan: "2" },
                            },
                            [
                              schedule.bkgClose === "Y"
                                ? _c("div", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("msg.CSBL100_M1.017"))
                                    ),
                                  ])
                                : schedule.bkgClose !== "M"
                                ? _c("div", [
                                    _c("ul", [
                                      _c("li", { staticClass: "mt5" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "button sm blue",
                                            on: {
                                              click: function ($event) {
                                                return _vm.bookingBooking4List(
                                                  schedule
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Booking")]
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _c("div", [_vm._v(" - ")]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    schedule.ts !== "Y" &&
                    schIdx >= _vm.startIdx &&
                    schIdx < _vm.endIdx
                      ? _c("tr", [
                          _c("td", { staticClass: "bor-left" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.VOSD100_M2.003")) +
                                    " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.polEtb,
                                        schedule.polEtbTm,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.VOSD100_M2.004")) +
                                    " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.etd,
                                        schedule.etdTm,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "td_underline",
                                    attrs: { href: "#none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openPopup(
                                          "PortTerminalCYPop",
                                          schedule.pol,
                                          schedule.polTml
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(schedule.otrmlCd) +
                                        " (" +
                                        _vm._s(schedule.otrmlNm) +
                                        ")"
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("td", [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.$t("msg.VOSD100_M2.003")) +
                                    " : " +
                                    _vm._s(
                                      _vm.changeDate2Display(
                                        schedule.eta,
                                        schedule.etaTm,
                                        _vm.lang
                                      )
                                    )
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "td_underline",
                                    attrs: { href: "#none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openPopup(
                                          "PortTerminalCYPop",
                                          schedule.pod,
                                          schedule.podTml
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(schedule.itrmlCd) +
                                        " (" +
                                        _vm._s(schedule.itrmlNm) +
                                        ")"
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
            _vm.pagingList.length > 0
              ? _c(
                  "div",
                  { staticClass: "paging" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn prev",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.getList4Schedule(_vm.curPage - 1)
                          },
                        },
                      },
                      [_vm._v(" Prev ")]
                    ),
                    _vm._l(_vm.pagingList, function (idx) {
                      return _c(
                        "a",
                        {
                          key: idx,
                          class: [idx === _vm.curPage ? "on" : ""],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.getList4Schedule(idx)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(idx) + " ")]
                      )
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "btn next",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.getList4Schedule(_vm.curPage + 1)
                          },
                        },
                      },
                      [_vm._v(" Next ")]
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.detailLayer2 === "Y" && _vm.calendarOrList === "C",
            expression: "detailLayer2 === 'Y' && calendarOrList === 'C'",
          },
        ],
        staticClass: "content_box mt10 div_detailLayer",
      },
      [
        _c("table", { staticClass: "tbl_col mt10" }, [
          _vm._m(7),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.005")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.002")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.003")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.030")))]),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", { staticClass: "text_left", attrs: { rowspan: "2" } }, [
                _vm.detailResp2.vslNm1 !== "Rail" &&
                _vm.detailResp2.vslNm1 !== "Truck"
                  ? _c(
                      "a",
                      {
                        class: _vm.urlOrNot
                          ? "td_underline dsm_info"
                          : "td_underline",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPopup("VesselScheduleInfoPop", 1)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.detailResp2.vslNm1) +
                            " " +
                            _vm._s(_vm.detailResp2.voyNo)
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.detailResp2.vslNm1 === "Rail" ||
                _vm.detailResp2.vslNm1 === "Truck"
                  ? _c("a", { class: _vm.urlOrNot ? "dsm_info" : "" }, [
                      _vm._v(
                        _vm._s(_vm.detailResp2.vslNm1) +
                          " " +
                          _vm._s(_vm.detailResp2.voyNo)
                      ),
                    ])
                  : _vm._e(),
                _c("br"),
                _vm.$ekmtcCommon.isNotEmpty(_vm.detailResp2.rteCd)
                  ? _c(
                      "a",
                      {
                        class: _vm.urlOrNot
                          ? "td_underline dsm_info"
                          : "td_underline",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPopup(
                              "RoutePop",
                              _vm.detailResp2.rteCd,
                              _vm.detailResp2.vslNm1
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "(ROUTE : " + _vm._s(_vm.detailResp2.rteCd) + ")"
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("br"),
                _vm.vslDelayReasonBtnFlag
                  ? _c(
                      "a",
                      {
                        staticClass: "button sm",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPopup("ChangeNoticePop")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.017")))]
                    )
                  : _vm._e(),
              ]),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detailResp2.ts !== "Y",
                      expression: "detailResp2.ts !== 'Y'",
                    },
                  ],
                  staticClass: "text_left",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "td_underline",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup(
                            "PortTerminalCYPop",
                            _vm.detailResp2.pol,
                            _vm.detailResp2.polTmlCd
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.detailResp2.polNm))]
                  ),
                ]
              ),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detailResp2.ts !== "Y",
                      expression: "detailResp2.ts !== 'Y'",
                    },
                  ],
                  staticClass: "text_left",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "td_underline",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup(
                            "PortTerminalCYPop",
                            _vm.detailResp2.pod,
                            _vm.detailResp2.podTmlCd
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.detailResp2.pod1Nm))]
                  ),
                ]
              ),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detailResp2.ts === "Y",
                      expression: "detailResp2.ts === 'Y'",
                    },
                  ],
                  staticClass: "text_left",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "td_underline",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup(
                            "PortTerminalCYPop",
                            _vm.detailResp2.pol1,
                            _vm.detailResp2.polTmlCd
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.detailResp2.polNm))]
                  ),
                ]
              ),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detailResp2.ts === "Y",
                      expression: "detailResp2.ts === 'Y'",
                    },
                  ],
                  staticClass: "text_left",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "td_underline",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup(
                            "PortTerminalCYPop",
                            _vm.detailResp2.pod1,
                            _vm.detailResp2.podTmlCd
                          )
                        },
                      },
                    },
                    [
                      _vm._v(_vm._s(_vm.detailResp2.pod1Nm) + "("),
                      _c("span", { staticClass: "color_red" }, [_vm._v("T/S")]),
                      _vm._v(")"),
                    ]
                  ),
                ]
              ),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detailResp2.ts !== "Y",
                      expression: "detailResp2.ts !== 'Y'",
                    },
                  ],
                  attrs: { rowspan: "2" },
                },
                [_vm._v(_vm._s(_vm.detailResp2.transitTime))]
              ),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detailResp2.ts === "Y",
                      expression: "detailResp2.ts === 'Y'",
                    },
                  ],
                  attrs: { rowspan: "2" },
                },
                [_vm._v(_vm._s(_vm.detailResp2.transitTime1))]
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "text_left border_left" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.007"))),
                  _c("span", { staticClass: "txt_bar" }),
                  _vm._v(" " + _vm._s(_vm.detailResp2.polEtbDT)),
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.008"))),
                  _c("span", { staticClass: "txt_bar" }),
                  _vm._v(" " + _vm._s(_vm.detailResp2.etdDT)),
                ]),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.detailResp2.ts !== "Y",
                        expression: "detailResp2.ts !== 'Y'",
                      },
                    ],
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "td_underline",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPopup(
                              "PortTerminalCYPop",
                              _vm.detailResp2.pol,
                              _vm.detailResp2.polTmlCd
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.detailResp2.polTml))]
                    ),
                  ]
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.detailResp2.ts === "Y",
                        expression: "detailResp2.ts === 'Y'",
                      },
                    ],
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "td_underline",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPopup(
                              "PortTerminalCYPop",
                              _vm.detailResp2.pol1,
                              _vm.detailResp2.polTmlCd
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.detailResp2.polTml))]
                    ),
                  ]
                ),
              ]),
              _c("td", { staticClass: "text_left" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.007"))),
                  _c("span", { staticClass: "txt_bar" }),
                  _vm._v(" " + _vm._s(_vm.detailResp2.etaDT)),
                ]),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.detailResp2.ts !== "Y",
                        expression: "detailResp2.ts !== 'Y'",
                      },
                    ],
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "td_underline",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPopup(
                              "PortTerminalCYPop",
                              _vm.detailResp2.pod,
                              _vm.detailResp2.podTmlCd
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.detailResp2.podTml))]
                    ),
                  ]
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.detailResp2.ts === "Y",
                        expression: "detailResp2.ts === 'Y'",
                      },
                    ],
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "td_underline",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPopup(
                              "PortTerminalCYPop",
                              _vm.detailResp2.pod1,
                              _vm.detailResp2.podTmlCd
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.detailResp2.podTml))]
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.detailResp2.ts === "Y",
                    expression: "detailResp2.ts === 'Y'",
                  },
                ],
              },
              [
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.069"))),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.detailResp2.transitTSTime2))]),
              ]
            ),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.detailResp2.ts === "Y",
                    expression: "detailResp2.ts === 'Y'",
                  },
                ],
              },
              [
                _c(
                  "td",
                  { staticClass: "text_left", attrs: { rowspan: "2" } },
                  [
                    _vm.detailResp2.vslNm2 !== "Rail" &&
                    _vm.detailResp2.vslNm2 !== "Truck"
                      ? _c(
                          "a",
                          {
                            staticClass: "td_underline",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openPopup("VesselScheduleInfoPop", 2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.detailResp2.vslNm2) +
                                " " +
                                _vm._s(_vm.detailResp2.voyNo2)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.detailResp2.vslNm2 === "Rail" ||
                    _vm.detailResp2.vslNm2 === "Truck"
                      ? _c("a", [
                          _vm._v(
                            _vm._s(_vm.detailResp2.vslNm2) +
                              " " +
                              _vm._s(_vm.detailResp2.voyNo2)
                          ),
                        ])
                      : _vm._e(),
                    _c("br"),
                    _vm.$ekmtcCommon.isNotEmpty(_vm.detailResp2.rteCd2)
                      ? _c(
                          "a",
                          {
                            staticClass: "td_underline",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openPopup(
                                  "RoutePop",
                                  _vm.detailResp2.rteCd2,
                                  _vm.detailResp2.vslNm2
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "(ROUTE : " + _vm._s(_vm.detailResp2.rteCd2) + ")"
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.detailResp2.tsDegree === "2" &&
                              _vm.vessel2ndButton === "Y",
                            expression:
                              "detailResp2.tsDegree === '2' && vessel2ndButton === 'Y'",
                          },
                        ],
                        staticClass: "mt10",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "button sm",
                            on: {
                              click: function ($event) {
                                return _vm.change2ndVessel(
                                  _vm.scheduleDetailData
                                )
                              },
                            },
                          },
                          [
                            _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.027"))),
                            _c("span", { staticClass: "btn_icon icoup" }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c("td", { staticClass: "text_left" }, [
                  _c(
                    "a",
                    {
                      staticClass: "td_underline",
                      attrs: { href: "#none" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup(
                            "PortTerminalCYPop",
                            _vm.detailResp2.pol2,
                            _vm.detailResp2.polTmlCd2
                          )
                        },
                      },
                    },
                    [
                      _vm._v(_vm._s(_vm.detailResp2.pod1Nm) + "("),
                      _c("span", { staticClass: "color_red" }, [_vm._v("T/S")]),
                      _vm._v(")"),
                    ]
                  ),
                ]),
                _c("td", { staticClass: "text_left" }, [
                  _c(
                    "a",
                    {
                      staticClass: "td_underline",
                      attrs: { href: "#none" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup(
                            "PortTerminalCYPop",
                            _vm.detailResp2.pod2,
                            _vm.detailResp2.podTmlCd2
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.detailResp2.pod2Nm))]
                  ),
                ]),
                _c("td", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.detailResp2.transitTime2)),
                ]),
              ]
            ),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.detailResp2.ts === "Y",
                    expression: "detailResp2.ts === 'Y'",
                  },
                ],
              },
              [
                _c("td", { staticClass: "text_left border_left" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.008"))),
                    _c("span", { staticClass: "txt_bar" }),
                    _vm._v(" " + _vm._s(_vm.detailResp2.polEtbDT2) + " "),
                  ]),
                  _c("p", [
                    _c(
                      "a",
                      {
                        staticClass: "td_underline",
                        attrs: { href: "#none" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPopup(
                              "PortTerminalCYPop",
                              _vm.detailResp2.pol2,
                              _vm.detailResp2.polTmlCd2
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.detailResp2.polTml2))]
                    ),
                  ]),
                ]),
                _c("td", { staticClass: "text_left" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.007"))),
                    _c("span", { staticClass: "txt_bar" }),
                    _vm._v(" " + _vm._s(_vm.detailResp2.etaDT2)),
                  ]),
                  _c("p", [
                    _c(
                      "a",
                      {
                        staticClass: "td_underline",
                        attrs: { href: "#none" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPopup(
                              "PortTerminalCYPop",
                              _vm.detailResp2.pod2,
                              _vm.detailResp2.podTmlCd2
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.detailResp2.podTml2))]
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.detailResp2.ts === "Y" &&
                      _vm.detailResp2.tsDegree >= 3,
                    expression:
                      "detailResp2.ts === 'Y' && detailResp2.tsDegree >= 3",
                  },
                ],
              },
              [
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.069"))),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.detailResp2.transitTSTime3))]),
              ]
            ),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.detailResp2.ts === "Y" &&
                      _vm.detailResp2.tsDegree >= 3,
                    expression:
                      "detailResp2.ts === 'Y' && detailResp2.tsDegree >= 3",
                  },
                ],
              },
              [
                _c(
                  "td",
                  { staticClass: "text_left", attrs: { rowspan: "2" } },
                  [
                    _vm.detailResp2.vslNm3 !== "Rail" &&
                    _vm.detailResp2.vslNm3 !== "Truck"
                      ? _c(
                          "a",
                          {
                            staticClass: "td_underline",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openPopup("VesselScheduleInfoPop", 3)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.detailResp2.vslNm3) +
                                " " +
                                _vm._s(_vm.detailResp2.voyNo3)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.detailResp2.vslNm3 === "Rail" ||
                    _vm.detailResp2.vslNm3 === "Truck"
                      ? _c("a", [
                          _vm._v(
                            _vm._s(_vm.detailResp2.vslNm3) +
                              " " +
                              _vm._s(_vm.detailResp2.voyNo3)
                          ),
                        ])
                      : _vm._e(),
                    _c("br"),
                    _vm.$ekmtcCommon.isNotEmpty(_vm.detailResp2.rteCd3)
                      ? _c(
                          "a",
                          {
                            staticClass: "td_underline",
                            attrs: { href: "#none" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openPopup(
                                  "RoutePop",
                                  _vm.detailResp2.rteCd3,
                                  _vm.detailResp2.vslNm3
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "(ROUTE : " + _vm._s(_vm.detailResp2.rteCd3) + ")"
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c("td", { staticClass: "text_left" }, [
                  _c(
                    "a",
                    { staticClass: "td_underline", attrs: { href: "#none" } },
                    [_vm._v(_vm._s(_vm.detailResp2.pod2Nm))]
                  ),
                ]),
                _c("td", { staticClass: "text_left" }, [
                  _c(
                    "a",
                    { staticClass: "td_underline", attrs: { href: "#none" } },
                    [_vm._v(_vm._s(_vm.detailResp2.pod3Nm))]
                  ),
                ]),
                _c("td", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.detailResp2.transitTime3)),
                ]),
              ]
            ),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.detailResp2.ts === "Y" &&
                      _vm.detailResp2.tsDegree >= 3,
                    expression:
                      "detailResp2.ts === 'Y' && detailResp2.tsDegree >= 3",
                  },
                ],
              },
              [
                _c("td", { staticClass: "text_left border_left" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.008"))),
                    _c("span", { staticClass: "txt_bar" }),
                    _vm._v(" " + _vm._s(_vm.detailResp2.polEtbDT3) + " "),
                  ]),
                  _c("p", [
                    _c(
                      "a",
                      { staticClass: "td_underline", attrs: { href: "#none" } },
                      [_vm._v(_vm._s(_vm.detailResp2.polTml3))]
                    ),
                  ]),
                ]),
                _c("td", { staticClass: "text_left" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.007"))),
                    _c("span", { staticClass: "txt_bar" }),
                    _vm._v(" " + _vm._s(_vm.detailResp2.etaDT3)),
                  ]),
                  _c("p", [
                    _c(
                      "a",
                      { staticClass: "td_underline", attrs: { href: "#none" } },
                      [_vm._v(_vm._s(_vm.detailResp2.podTml3))]
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.detailResp2.ts === "Y" &&
                      _vm.detailResp2.tsDegree >= 4,
                    expression:
                      "detailResp2.ts === 'Y' && detailResp2.tsDegree >= 4",
                  },
                ],
              },
              [
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.069"))),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.detailResp2.transitTSTime4))]),
              ]
            ),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.detailResp2.ts === "Y" &&
                      _vm.detailResp2.tsDegree >= 4,
                    expression:
                      "detailResp2.ts === 'Y' && detailResp2.tsDegree >= 4",
                  },
                ],
              },
              [
                _c(
                  "td",
                  { staticClass: "text_left", attrs: { rowspan: "2" } },
                  [
                    _vm.detailResp2.vslNm4 !== "Rail" &&
                    _vm.detailResp2.vslNm4 !== "Truck"
                      ? _c(
                          "a",
                          {
                            staticClass: "td_underline",
                            attrs: { href: "#none" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openPopup("VesselScheduleInfoPop", 4)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.detailResp2.vslNm4) +
                                " " +
                                _vm._s(_vm.detailResp2.voyNo4)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.detailResp2.vslNm4 === "Rail" ||
                    _vm.detailResp2.vslNm4 === "Truck"
                      ? _c("a", [
                          _vm._v(
                            _vm._s(_vm.detailResp2.vslNm4) +
                              " " +
                              _vm._s(_vm.detailResp2.voyNo4)
                          ),
                        ])
                      : _vm._e(),
                    _c("br"),
                    _vm.$ekmtcCommon.isNotEmpty(_vm.detailResp2.rteCd4)
                      ? _c(
                          "a",
                          {
                            staticClass: "td_underline",
                            attrs: { href: "#none" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openPopup(
                                  "RoutePop",
                                  _vm.detailResp2.rteCd4,
                                  _vm.detailResp2.vslNm4
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "(ROUTE : " + _vm._s(_vm.detailResp2.rteCd4) + ")"
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c("td", { staticClass: "text_left" }, [
                  _c(
                    "a",
                    { staticClass: "td_underline", attrs: { href: "#none" } },
                    [_vm._v(_vm._s(_vm.detailResp2.pod3Nm))]
                  ),
                ]),
                _c("td", { staticClass: "text_left" }, [
                  _c(
                    "a",
                    { staticClass: "td_underline", attrs: { href: "#none" } },
                    [_vm._v(_vm._s(_vm.detailResp2.pod4Nm))]
                  ),
                ]),
                _c("td", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.detailResp2.transitTime4)),
                ]),
              ]
            ),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.detailResp2.ts === "Y" &&
                      _vm.detailResp2.tsDegree >= 4,
                    expression:
                      "detailResp2.ts === 'Y' && detailResp2.tsDegree >= 4",
                  },
                ],
              },
              [
                _c("td", { staticClass: "text_left border_left" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.008"))),
                    _c("span", { staticClass: "txt_bar" }),
                    _vm._v(" " + _vm._s(_vm.detailResp2.polEtbDT4) + " "),
                  ]),
                  _c("p", [
                    _c(
                      "a",
                      { staticClass: "td_underline", attrs: { href: "#none" } },
                      [_vm._v(_vm._s(_vm.detailResp2.polTml4))]
                    ),
                  ]),
                ]),
                _c("td", { staticClass: "text_left" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.007"))),
                    _c("span", { staticClass: "txt_bar" }),
                    _vm._v(" " + _vm._s(_vm.detailResp2.etaDT4)),
                  ]),
                  _c("p", [
                    _c(
                      "a",
                      { staticClass: "td_underline", attrs: { href: "#none" } },
                      [_vm._v(_vm._s(_vm.detailResp2.podTml4))]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show2ndVessel === "Y" && _vm.calendarOrList === "C",
                expression: "show2ndVessel === 'Y' && calendarOrList === 'C'",
              },
            ],
            staticClass: "gr_tbl_box",
          },
          [
            _c("div", [
              _c("p", { staticClass: "port" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.detailResp2.pod1Nm.split(",")[0])),
                ]),
                _c("span", [
                  _vm._v(_vm._s(_vm.detailResp2.pod2Nm.split(",")[0])),
                ]),
              ]),
              _c("p", { staticClass: "txt" }, [
                _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.028"))),
              ]),
              _c("div", { staticClass: "btn_port_cancel" }, [
                _c(
                  "a",
                  {
                    staticClass: "sm gray button",
                    on: {
                      click: function ($event) {
                        return _vm.cancel2ndVessel()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.029")))]
                ),
              ]),
            ]),
            _c("table", { staticClass: "tbl_col t2 tac mt10" }, [
              _vm._m(8),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("menu.MENU09.060")))]),
                  _c("th", [_vm._v("ETD")]),
                  _c("th", [_vm._v("ETA")]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL100_M1.015")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.075")))]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.vessel2ndList, function (list, idx) {
                  return _c("tr", { key: idx }, [
                    _c("td", [
                      _vm._v(_vm._s(list.vslNm) + " " + _vm._s(list.voyNo)),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.etdStrDT))]),
                    _c("td", [_vm._v(_vm._s(list.etaStrDT))]),
                    _c("td", [_vm._v(_vm._s(list.transitTime))]),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "button gray sm",
                          on: {
                            click: function ($event) {
                              return _vm.select2ndVessel(list)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.CSBL200.075")))]
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]
        ),
        _c("P", { staticClass: "mt5 text_right" }, [
          _vm._v(
            _vm._s(_vm.$t("msg.VOSD100_M5.110")) +
              " : " +
              _vm._s(_vm.detailResp2.transitTime)
          ),
        ]),
        _vm.bound === "O"
          ? [
              _c("close-info-sch", {
                ref: "CloseInfo",
                attrs: { "parent-info": _vm.closeInfoSch },
              }),
            ]
          : _vm._e(),
        _vm.bound === "I"
          ? [
              _c("div", [
                _c("table", { staticClass: "tbl_row mt10" }, [
                  _vm._m(9),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t("msg.VOSD100_M2.003")) +
                            " " +
                            _vm._s(_vm.$t("msg.MAIN100.006")) +
                            " MRN NO."
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.detailResp2.mrnNo))]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t("msg.VOSD100_M2.003")) +
                            " " +
                            _vm._s(_vm.$t("msg.MAIN100.006")) +
                            " " +
                            _vm._s(_vm.$t("msg.CSCT060G030.035"))
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.detailResp2.apoTcnt))]),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.$t("msg.VOSD100_M2.003")) +
                            " " +
                            _vm._s(_vm.$t("msg.MAIN100.006")) +
                            " CALL SIGN"
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.detailResp2.callSign))]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.afterClickDetail === "Y",
            expression: "afterClickDetail === 'Y'",
          },
        ],
        staticClass: "flex_box mt10",
      },
      [
        _vm.parentInfo.cancelRequestPop !== "Y" &&
        _vm.bound === "O" &&
        _vm.parentInfo.yzrYn !== "Y"
          ? _c("div", { staticClass: "ml_auto" }, [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.frtResult === "Y" ||
                        (_vm.frtResult === "A" && _vm.rmPopOpenFlag),
                      expression:
                        "frtResult === 'Y' || (frtResult === 'A' && rmPopOpenFlag)",
                    },
                  ],
                  class: _vm.urlOrNot
                    ? "button blue lg mr5 action"
                    : "button blue lg mr5",
                  on: { click: _vm.openFreSurchargePopup },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("menu.MENU05.000")) +
                      _vm._s(_vm.$t("btn.CMBTK013"))
                  ),
                ]
              ),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.frtResult === "N" ||
                        (_vm.frtResult === "A" && !_vm.rmPopOpenFlag),
                      expression:
                        "frtResult === 'N' || (frtResult === 'A' && !rmPopOpenFlag)",
                    },
                  ],
                  staticClass: "button blue lg mr5",
                  on: { click: _vm.openRequestQuotePopup },
                },
                [_vm._v(_vm._s(_vm.$t("menu.MENU05.010")))]
              ),
              _c(
                "a",
                {
                  class: _vm.urlOrNot
                    ? "button blue lg action"
                    : "button blue lg",
                  on: {
                    click: function ($event) {
                      return _vm.beforeGoBooking(_vm.detailResp2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.CSBL100_M1.016")))]
              ),
            ])
          : _vm._e(),
        _vm.parentInfo.cancelRequestPop !== "Y" &&
        _vm.bound === "I" &&
        _vm.parentInfo.yzrYn !== "Y"
          ? _c("div", { staticClass: "ml_auto" }, [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.frtResult === "Y" ||
                        (_vm.frtResult === "A" && _vm.rmPopOpenFlag),
                      expression:
                        "frtResult === 'Y' || (frtResult === 'A' && rmPopOpenFlag)",
                    },
                  ],
                  class: _vm.urlOrNot
                    ? "button blue lg mr5 action"
                    : "button blue lg mr5",
                  on: { click: _vm.openFreSurchargePopup },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("menu.MENU05.000")) +
                      _vm._s(_vm.$t("btn.CMBTK013"))
                  ),
                ]
              ),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.frtResult === "N" ||
                        (_vm.frtResult === "A" && !_vm.rmPopOpenFlag),
                      expression:
                        "frtResult === 'N' || (frtResult === 'A' && !rmPopOpenFlag)",
                    },
                  ],
                  staticClass: "button blue lg mr5",
                  on: { click: _vm.openRequestQuotePopup },
                },
                [_vm._v(_vm._s(_vm.$t("menu.MENU05.010")))]
              ),
            ])
          : _vm._e(),
        _vm.parentInfo.cancelRequestPop === "Y" && _vm.parentInfo.yzrYn !== "Y"
          ? _c("div", { staticClass: "ml_auto" }, [
              _c(
                "a",
                {
                  class: _vm.urlOrNot
                    ? "button blue lg action"
                    : "button blue lg",
                  on: {
                    click: function ($event) {
                      return _vm.beforeGoBooking(_vm.detailResp2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.CSBK100.062")))]
              ),
            ])
          : _vm._e(),
        _vm.parentInfo.yzrYn === "Y"
          ? _c("div", { staticClass: "ml_auto" }, [
              _c(
                "a",
                {
                  staticClass: "button blue lg",
                  on: {
                    click: function ($event) {
                      return _vm.beforeGoBooking(_vm.detailResp2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.CSBK100.137")))]
              ),
            ])
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.afterClickDetail !== "Y" &&
              _vm.detailLayer2 === "Y" &&
              _vm.kmtcPremiumClosYn === "N" &&
              _vm.kmtcPremiumUserYn === "Y" &&
              _vm.kmtcPremiumLineYn === "Y",
            expression:
              "afterClickDetail !== 'Y' && detailLayer2 === 'Y' && kmtcPremiumClosYn === 'N' && kmtcPremiumUserYn === 'Y' && kmtcPremiumLineYn === 'Y'",
          },
        ],
        staticClass: "flex_box mt10",
      },
      [
        _vm.parentInfo.cancelRequestPop !== "Y" && _vm.bound === "O"
          ? _c("div", { staticClass: "ml_auto" }, [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.frtResult === "Y" ||
                        (_vm.frtResult === "A" && _vm.rmPopOpenFlag),
                      expression:
                        "frtResult === 'Y' || (frtResult === 'A' && rmPopOpenFlag)",
                    },
                  ],
                  class: _vm.urlOrNot
                    ? "button blue lg mr5 action"
                    : "button blue lg mr5",
                  on: { click: _vm.openFreSurchargePopup },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("menu.MENU05.000")) +
                      _vm._s(_vm.$t("btn.CMBTK013"))
                  ),
                ]
              ),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.frtResult === "N" ||
                        (_vm.frtResult === "A" && !_vm.rmPopOpenFlag),
                      expression:
                        "frtResult === 'N' || (frtResult === 'A' && !rmPopOpenFlag)",
                    },
                  ],
                  staticClass: "button blue lg mr5",
                  on: { click: _vm.openRequestQuotePopup },
                },
                [_vm._v(_vm._s(_vm.$t("menu.MENU05.010")))]
              ),
              _c(
                "a",
                {
                  class: _vm.urlOrNot
                    ? "button blue lg action"
                    : "button blue lg",
                  on: {
                    click: function ($event) {
                      return _vm.beforeGoBooking(_vm.detailResp2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.CSBL100_M1.016")))]
              ),
              _vm.parentInfo.yzrYn === "Y"
                ? _c(
                    "a",
                    {
                      staticClass: "button blue lg",
                      on: {
                        click: function ($event) {
                          return _vm.beforeGoBooking(_vm.detailResp2)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.CSBK100.137")))]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.detailLayer2 === "Y" &&
              _vm.kmtcPremiumClosYn === "N" &&
              _vm.kmtcPremiumUserYn === "Y" &&
              _vm.kmtcPremiumLineYn === "Y",
            expression:
              "detailLayer2 === 'Y' && kmtcPremiumClosYn === 'N' && kmtcPremiumUserYn === 'Y' && kmtcPremiumLineYn === 'Y'",
          },
        ],
        staticClass: "flex_box mt10",
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              color: "#075bb9",
              "font-weight": "500",
              "font-size": "15px",
              "margin-left": "auto !important",
              "margin-top": "-10px",
            },
          },
          [_vm._v("PREMIUM 부킹 가능      ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "90px" } }),
      _c("col"),
      _c("col", { attrs: { width: "90px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mr10", attrs: { for: "bk2" } }, [
      _c("span"),
      _vm._v("RF"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "oper1" } }, [
      _c("span"),
      _vm._v("KMTC"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "oper2" } }, [
      _c("span"),
      _vm._v("Joint"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { span: "7", width: "14.2%" } })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "18%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "22%" } }),
      _c("col", { attrs: { width: "34%" } }),
      _c("col", { attrs: { width: "34%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "18%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }